<template>
  <TModal
    :title="title"
    size="lg"
    :show="show"
    @update:show="$emit('update:show', $event)"
    :fetching="fetching"
  >
    <template #actions>
      <div
        class="w-100 d-flex align-items-center justify-content-between"
        v-if="hasRemoveBoxesListener"
      >
        <div class="d-flex">
          <TMessage content="Selected" bold />
          <span class="ml-2">{{ skuSelected.length }}</span>
          <CLink
            class="ml-2 d-flex align-items-center"
            @click="onSelectAll"
            v-if="skuSelected.length < data_table.length"
          >
            <CIcon name="cil-check" class="mr-1" />
            <TMessage content="Select all" bold />
          </CLink>
          <CLink
            class="ml-2 d-flex text-danger align-items-center"
            @click="skuSelected = []"
            v-if="skuSelected.length"
          >
            <CIcon name="cil-x-circle" class="mr-1" />
            <TMessage content="Deselect all" bold color="text-danger" />
          </CLink>
        </div>

        <div>
          <TSpinner
            :loading="removing || fetching"
            v-if="removing || fetching"
          />
          <TButtonRemove
            @click="$emit('remove-boxes', skuSelected)"
            :options="{ content: 'Unload box from container' }"
            v-if="!!skuSelected.length && !removing"
          />
        </div>
      </div>
      <span v-else></span>
    </template>

    <SInputSku
      :disabled="!data_table.length"
      @enter="onPickSku"
      placeholder="Select SKU"
      v-if="hasRemoveBoxesListener"
    />

    <TTableSimple :fields="referenceFields" :items="data_table" noResult>
      <template #_="{ item }">
        <td>
          <div class="d-flex align-items-center justify-content-center">
            <TButtonRemove
              v-if="!item.removing"
              @click="$emit('remove', item, $event)"
            />
            <TSpinner v-else :loading="true" />

            <div class="ml-3" v-if="hasRemoveBoxesListener">
              <TInputCheckbox
                :checked="lodash.findIndex(skuSelected, ['id', item.id]) !== -1"
                @change="selectSku(item)"
              />
            </div>
          </div>
        </td>
      </template>
      <template #quantity_picked="{ item }">
        <td>
          <TMessageNumber
            :value="item.quantity_picked"
            editable
            @change="$emit('focus-quantity:change', item, $event)"
          />
        </td>
      </template>
      <template #referer_quantity="{ item }">
        <td>
          <TMessageNumber :value="item.referer_quantity" />
        </td>
      </template>
      <template #updated_at="{ item }">
        <td>
          <TMessageDateTime :content="item.updated_at" />
        </td>
      </template>
    </TTableSimple>
  </TModal>
</template>

<script>
import audio from "../mixins/audio";

export default {
  mixins: [audio],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "Watch boxes was in container",
    },
    pickers: {
      type: Array,
      default: () => [],
    },
    referer_quantity_key: {
      type: String,
      default: () => "box.quantity_in_goods_delivery",
    },
    fetching: {
      type: Boolean,
      default: false,
    },
    removing: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      referenceFields: [
        {
          key: "_",
          label: "#",
        },
        {
          key: "box_id",
          label: "SKU",
        },
        {
          key: "pallet_id",
          label: "Pallet",
        },
        {
          key: "quantity_picked",
          label: "Picked quantity",
        },
        {
          key: "referer_quantity",
          label: "Referer quantity",
        },
        {
          key: "updated_at",
          label: "Updated at",
        },
      ],
      skuSelected: [],
    };
  },
  computed: {
    data_table() {
      return this.pickers.map((picker) => ({
        ...this.lodash.pick(picker, ["box_id", "id", "removing", "updated_at"]),
        pallet_id: picker?.pallet_id || "___",
        quantity_picked: picker.quantity,
        referer_quantity: this.lodash.get(picker, this.referer_quantity_key),
      }));
    },
    hasRemoveBoxesListener() {
      return this.$listeners && this.$listeners["remove-boxes"];
    },
  },
  watch: {
    data_table: {
      immediate: false,
      handler() {
        this.skuSelected = [];
      },
    },
    show: {
      immediate: false,
      handler() {
        this.skuSelected = [];
      },
    },
  },
  methods: {
    selectSku(item) {
      const index = this.skuSelected.findIndex((x) => x.id === item.id);
      if (index < 0) {
        this.skuSelected.push(item);
      } else this.skuSelected.splice(index, 1);
    },
    onSelectAll() {
      this.data_table.forEach((box) => {
        const check = this.skuSelected.find((x) => x.id == box.id);
        if (!check) this.selectSku(box);
      });
    },
    onPickSku(code) {
      if (!code) return;

      let matched_skus = this.data_table.filter((i) => i.box_id === code);
      let matched_sku_selected_idx = this.skuSelected.findIndex(
        (i) => i.box_id === code
      );

      if (matched_skus.length == 0) {
        this.playFail();

        return;
      }

      this.playSuccess();

      if (matched_sku_selected_idx !== -1) return;

      matched_skus.forEach((matched_sku) => {
        this.selectSku(matched_sku);
      });
    },
  },
};
</script>
