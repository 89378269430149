<template>
  <TModal
    title="Watch data was been error"
    size="lg"
    :show="show"
    @update:show="$emit('update:show', $event)"
  >
    <template #actions>
      <span>
        <TButtonSave
          @click="exportDataToCSV"
          :options="{ content: 'Export CSV' }"
        />
      </span>
    </template>
    <TTableSimple :fields="referenceFields" :items="data_table" noResult>
      <template #_="{ item }">
        <td>
          <div>{{ item.idx }}</div>
        </td>
      </template>
      <template #data="{ item }">
        <td>
          <div>{{ item.data }}</div>
        </td>
      </template>
      <template #type="{ item }">
        <td>
          <div>{{ item.type }}</div>
        </td>
      </template>
    </TTableSimple>
  </TModal>
</template>

<script>
// import { mapGetters } from "vuex";
import barcode from "../mixins/barcode";
import audio from "../mixins/audio";

export default {
  mixins: [barcode, audio],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    pickers: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      referenceFields: [
        {
          key: "_",
          label: "#",
        },
        {
          key: "data",
          label: "Data",
        },
        {
          key: "type",
          label: "Type",
        },
      ],
    };
  },
  computed: {
    data_table() {
      return this.pickers.map((i, index) => ({ idx: index + 1, ...i }));
    },
  },
  methods: {
    exportDataToCSV() {
      const rows = [
        ["Index", "Picker", "Type"],
        ...this.pickers.map((i, index) => [index + 1, i.data, i.type]),
      ];

      let csvContent =
        "data:text/csv;charset=utf-8," +
        rows.map((e) => e.join(",")).join("\n");

      var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "my_data.csv");
      document.body.appendChild(link); // Required for FF

      link.click();
    },
  },
};
</script>
