<template>
  <CRow class="mb-5">
    <CCol lg="3">
      <TAside>
        <CListGroup>
          <CListGroupItem>
            <TInputSimpleSearch
              placeholder="Search invoice"
              :value.sync="search"
            />
          </CListGroupItem>
          <CListGroupItem
            v-for="(invoice, index) in invoices"
            :key="`${index}${invoice.id}`"
            :color="invoice_id_selected == invoice.id ? 'primary' : 'secondary'"
            :class="[
              'p-0',
              {
                'list-group-item-success':
                  invoice.had_cut_off && !(invoice_id_selected == invoice.id),
              },
            ]"
          >
            <SCardInvoice
              class="cursor-pointer"
              @click.native="
                lodash.appendRouteQuery($router, {
                  query: { invoice_id: invoice.id },
                })
              "
              :invoice="invoice"
            />
          </CListGroupItem>
          <CListGroupItem v-if="!invoices.length">
            <TMessageNotFound />
          </CListGroupItem>
          <CListGroupItem v-if="list_fetching">
            <TSpinner :loading="list_fetching" />
          </CListGroupItem>
          <SButtonLoadmore store="warehouse.invoices_out" />
        </CListGroup>
      </TAside>
    </CCol>
    <CCol lg="9">
      <CRow v-if="invoice_id_selected">
        <!-- Card overview xuất kho -->
        <CCol col="12">
          <CCard v-if="invoice_selected">
            <CCardHeader class="d-flex align-items-center">
              <TMessage content="SKUs in invoice">
                <template #suffix>
                  <span class="ml-1 font-weight-bold">{{
                    invoice_selected.name || "..."
                  }}</span>
                </template>
              </TMessage>
              <TSpinner :loading="detail_loading" />
              <div class="ml-auto">
                <SButtonStatusInvoice
                  :invoice="invoice_selected"
                  store="warehouse.invoices_out"
                />
                <TButtonRefresh
                  @click="
                    $store.dispatch('warehouse.invoices_out.detail.refresh')
                  "
                  class="ml-2"
                  size=""
                  :options="{ disabled: detail_loading }"
                />
              </div>
            </CCardHeader>
            <CCardBody>
              <CRow>
                <CCol col="6">
                  <TMessage
                    content="Total number of boxes above expected"
                    bold
                    class="h5"
                  >
                    <template #suffix>: {{ count_duplicate }} </template>
                  </TMessage>
                </CCol>
                <CCol col="6">
                  <TMessage
                    content="Total number of boxes downloaded"
                    bold
                    class="h5"
                  >
                    <template #suffix>: {{ count_outcont }} </template>
                  </TMessage>
                </CCol>
              </CRow>

              <!-- <div class="d-flex flex-wrap">
                <SCardSku
                  v-for="box in boxes_in_lading_bills"
                  :key="box.id"
                  :box="box"
                  class="my-3 mx-2"
                  refresh
                  style="width: 170px"
                  :focus-quantity="box.quantity_out_picker_in_cont"
                  focus-quantity-color="success"
                  :border="getBorderColor(box)"
                  mainQuantityDescription="Expected quantity"
                  focusQuantityDescription="Quantity out cont"
                  :showWeight="false"
                  :showSfa="false"
                >
                  <template #main-quantity>
                    <TMessageNumber
                      :value="box.total_quantity_in_lading_bills"
                    />
                  </template>
                </SCardSku>
              </div> -->
            </CCardBody>
          </CCard>
        </CCol>

        <!-- Card input nhập kho -->
        <CCol col="12">
          <CCard v-if="pick_boxes">
            <CCardBody>
              <SInputSku
                :disabled="!invoice_selected.had_cut_off"
                @enter="onEnterPicker"
              />
            </CCardBody>
            <CCardFooter>
              <div class="d-flex align-items-center justify-content-end">
                <TButton
                  content="Export excel"
                  icon="cid-cloud-download"
                  size="sm"
                  variant="ghost"
                  color="danger"
                  @click="exportPicker"
                />
                <TButtonQuickView
                  :options="{ content: 'Watch boxes was out container' }"
                  @click="showModalPickedBox = true"
                />

                <TButton
                  @click="showModalPickedBoxError = true"
                  color="danger"
                  variant="ghost"
                  size="sm"
                  icon="cil-magnifying-glass"
                  v-if="boxes_picked_error.length"
                >
                  <template #content>
                    <TMessage
                      class="ml-1"
                      content="Watch data was been error"
                    />
                    <CBadge
                      color="danger"
                      class="ml-1 d-flex align-items-center justify-content-center"
                    >
                      <span style="font-size: 12px">{{
                        boxes_picked_error.length
                      }}</span>
                    </CBadge>
                  </template>
                </TButton>

                <PickedBoxModal
                  :show.sync="showModalPickedBox"
                  title="Watch boxes was out container"
                  :pickers="out_container_pickeds"
                  @focus-quantity:change="updateQuantityPicker"
                  @remove="removePicker"
                  referer_quantity_key="current_quantity_in_lading_bills"
                  :fetching="out_container_pickers_loading"
                />

                <PickedBoxErrorModal
                  :show.sync="showModalPickedBoxError"
                  :pickers="boxes_picked_error"
                />
              </div>
            </CCardFooter>
          </CCard>
        </CCol>

        <!-- [8] Card hàng đợi nhập kho -->
        <CCol col="8">
          <CCard>
            <CCardHeader>
              <div class="d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center">
                  <TMessage content="Pick out container">
                    <template #suffix>
                      <span class="ml-1 font-weight-bold">{{
                        invoice_selected.name || "..."
                      }}</span>
                    </template>
                  </TMessage>
                  <TSpinner :loading="out_container_pickers_loading" />
                </div>
                <TButton
                  v-if="invoice_selected.had_cut_off"
                  color="primary"
                  :messageOptions="{ bold: true }"
                  icon="cid-cloud-upload"
                  @click="showModalImportExcel = true"
                  content="Import excel"
                  class="mr-1 ml-auto"
                  variant="outline"
                />
                <TButton
                  color="success"
                  :messageOptions="{ bold: true, noTranslate: true }"
                  icon="cil-data-transfer-down"
                  :options="{
                    disabled:
                      !picking_boxes.length || out_container_pickers_loading,
                  }"
                  @click="submitPickers"
                >
                  <template #content>
                    <TMessage content="Import" bold class="ml-2">
                      <template #suffix>
                        <span v-if="lodash.sumBy(picking_boxes, 'quantity')">
                          {{
                            `(${_.sumBy(picking_boxes, "quantity")} ${$t(
                              "boxes"
                            )})`
                          }}
                        </span>
                      </template>
                    </TMessage>
                  </template>
                </TButton>
              </div>
              <ImportExcelSKU
                :show.sync="showModalImportExcel"
                :container_id="invoice_id_selected"
                type="outcont"
                @updated="submitExcelSuccess"
              />
            </CCardHeader>
            <CCardBody class="p-3">
              <TMessageNoData content="No SKU" v-if="!pick_boxes.length" />
              <div
                class="my-1 mx-2"
                v-for="(picker, index) in pick_boxes"
                :key="`${picker.id} - ${index}`"
              >
                <SCardSkuContainerPicker
                  :box="picker.box"
                  class="w-100"
                  :focus-quantity="picker.quantity"
                  :focus-quantity-ediable="true"
                  @focus-quantity:change="updateQuantityPicker(picker, $event)"
                  :removable="true"
                  @remove="removePicker(picker, $event)"
                  :removing="picker.removing"
                  :updating="picker.updating"
                  :background="getBoxCardPickingBackground(picker)"
                  :noConfirmOnRemove="picker.picking"
                >
                  <template #picked_by_pallet v-if="!picker.picking">
                    <CBadge
                      v-if="picker.pallet_id"
                      color="success"
                      class="my-auto"
                    >
                      {{ picker.pallet_id }}
                    </CBadge>
                  </template>

                  <template #main-quantity>
                    <TMessageNumber
                      :value="getTotalQuantityInLadingBills(picker)"
                    />
                  </template>
                </SCardSkuContainerPicker>
              </div>
            </CCardBody>
          </CCard>
        </CCol>

        <!-- [4] Card chi tiết các thông tin thùng nhập kho -->
        <CCol col="4">
          <CCard v-if="boxes_in_lading_bills">
            <CCardBody class="py-4 px-2">
              <TMessageNoData
                content="No SKU"
                v-if="!boxes_in_lading_bills.length"
              />
              <SCardSkuContainerPicker
                v-for="box in boxes_in_lading_bills"
                :key="box.id"
                :box="box"
                class="w-100"
                refresh
                :focus-quantity="box.quantity_out_picker_in_cont"
                :background="getBoxCardBackground(box)"
                :focus-quantity-title="$t('Box quantity out picker')"
              >
                <template
                  #prepend-quantity
                  v-if="pickingBoxQuantityById(box.id)"
                >
                  <div style="font-size: 20px; font-weight: 400">
                    <TMessageNumber
                      :value="pickingBoxQuantityById(box.id)"
                      :title="$t('Picking quantity')"
                    />
                  </div>
                </template>

                <template #main-quantity>
                  <div
                    class="font-weight-bold bg-light p-1"
                    style="font-size: 14px; min-width: 40px; border-radius: 5px"
                  >
                    <TMessageNumber
                      :value="getPickedInContainerQuantity(box.id)"
                      :messageOptions="{ alignment: 'center' }"
                      :title="$t('Quantity in cont')"
                    />
                    <hr class="my-0" />
                    <TMessageNumber
                      :value="box.total_quantity_in_lading_bills"
                      :messageOptions="{ alignment: 'center' }"
                      :title="$t('Quantity in lading bill')"
                    />
                  </div>
                </template>
              </SCardSkuContainerPicker>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </CCol>
  </CRow>
</template>

<script>
import audio from "../../mixins/audio";
import readDWSSKU from "../../mixins/readDWSSKU";
import barcode from "../../mixins/barcode";
import searchable from "../../mixins/searchable";
import PICK_OUT_CONT from "../../store/pick_out_cont.local";
import warehouseScope from "@/core/services/tomoni/warehouse_scope.local";
import PickedBoxModal from "../../components/PickedBoxModal.vue";
import PickedBoxErrorModal from "../../components/PickedBoxErrorModal.vue";
import ImportExcelSKU from "../../components/ImportExcelSKU.vue";

export default {
  mixins: [searchable, barcode, audio, readDWSSKU],
  components: { PickedBoxModal, PickedBoxErrorModal, ImportExcelSKU },
  data() {
    return {
      picking_boxes: [],
      in_api_process_boxes: [],
      showModalPickedBox: false,
      boxes_picked_error: [],
      showModalPickedBoxError: false,
      showModalImportExcel: false,
    };
  },
  watch: {
    invoice_id_selected: {
      immediate: true,
      handler(id) {
        if (id) {
          this.$store
            .dispatch("warehouse.invoices_out.detail.fetch", id)
            .then(() => {
              if (this.$store.getters["printer.value"]?.readyState) {
                this.$store.dispatch(
                  "printer.send-message",
                  JSON.stringify({
                    type: "ReadDWS",
                    Command: id,
                  })
                );
              }
            })
            .catch(() => {
              this.$router.push("/warehouse/inbound/transfer-order");
            });
          this.scopeSelected = "expected";
          this.fetchOutPickers();
          this.fetchPickingBoxes();
          this.boxes_picked_error = [];
        }
      },
    },
  },
  computed: {
    invoice_id_selected() {
      return this.$route.query.invoice_id;
    },
    invoice_selected() {
      let invoice = this.$store.getters["warehouse.invoices_out.detail"];
      if (!this.lodash.isEmpty(invoice))
        invoice.boxes_in_lading_bills = invoice?.boxes_in_lading_bills?.map(
          (box) => {
            const quantity = this.lodash.sumBy(
              invoice.out_pickers.filter((x) => x.box_id == box.id),
              "quantity"
            );
            return {
              ...box,
              quantity_out_picker_in_cont: quantity,
            };
          }
        );
      return invoice;
    },
    invoices() {
      return this.$store.getters["warehouse.invoices_out.list"];
    },
    lading_bills() {
      return this.$store.getters["warehouse.lading_bills.list"];
    },
    list_fetching() {
      return this.$store.getters["warehouse.invoices_out.fetching"];
    },
    detail_loading() {
      return this.$store.getters["warehouse.invoices_out.detail.loading"];
    },
    invoices_creating() {
      return this.$store.getters["warehouse.invoices_out.creating"];
    },
    out_container_pickers() {
      return this.$store.getters["warehouse.out_container_pickers.list"];
    },
    out_container_pickeds() {
      return this.$store.getters["warehouse.out_container_pickers.list"].map(
        (i) => {
          return {
            ...i,
            current_quantity_in_lading_bills:
              this.getTotalQuantityInLadingBills({ box: { id: i.box_id } }),
            current_quantity_in_picker: this.getPickedInContainerQuantity(
              i.box_id
            ),
          };
        }
      );
    },
    out_container_pickers_loading() {
      return this.$store.getters["warehouse.out_container_pickers.loading"];
    },
    pick_boxes() {
      return (
        [
          ...this.picking_boxes,
          ...this.in_api_process_boxes,
          // ...this.out_container_pickers.filter((p) => p.box),
        ] || []
      );
    },
    boxes_in_lading_bills() {
      return this.invoice_selected?.boxes_in_lading_bills || [];
    },
    count_actual_boxes_in_lading_bills() {
      return (
        this.invoice_selected?.boxes_in_lading_bills?.filter(
          (x) =>
            x.quantity_out_picker_in_cont >= x?.total_quantity_in_lading_bills
        )?.length || 0
      );
    },
    count_remaining_boxes_in_lading_bills() {
      return (
        this.invoice_selected?.boxes_in_lading_bills?.filter(
          (x) =>
            x.quantity_out_picker_in_cont < x?.total_quantity_in_lading_bills
        )?.length || 0
      );
    },
    count_boxes_in_lading_bills() {
      return this.invoice_selected?.boxes_in_lading_bills?.length || 0;
    },
    count_duplicate() {
      return (
        this.lodash.sumBy(
          this.invoice_selected?.boxes_in_lading_bills,
          "total_quantity_in_lading_bills"
        ) || 0
      );
    },
    count_outcont() {
      return (
        this.lodash.sumBy(this.invoice_selected?.out_pickers, "quantity") || 0
      );
    },
  },
  methods: {
    handleReadDWSSKU(e) {
      const data = JSON.parse(e.data);
      if (data.type == "DWS_SKU") {
        this.onEnterPicker(data.box_id);
      }
    },
    getPickedInContainerQuantity(box_id) {
      return (this.invoice_selected?.in_pickers || [])
        .filter((i) => i.box_id === box_id)
        .reduce((p, c) => p + c.quantity, 0);
    },
    pickingBoxQuantityById(box_id) {
      return this.pick_boxes
        .filter((x) => x.id === box_id)
        .reduce((p, c) => p + c.quantity, 0);
    },
    onEnterDataError(k, type) {
      this.playFail();
      this.boxes_picked_error.push({ data: k, type });
    },
    fetchOutPickers() {
      return this.$store.dispatch(
        "warehouse.out_container_pickers.apply-query",
        {
          "filter[container_id]": this.invoice_id_selected,
        }
      );
    },
    fetchPickingBoxes() {
      this.picking_boxes = PICK_OUT_CONT.get(this.invoice_id_selected) || [];
    },
    fetchInvoice() {
      this.$store.dispatch("warehouse.invoices_out.detail.refresh");
    },
    pickBarcode(e) {
      const code = this.press(e);
      if (code) {
        this.onEnterPicker(code);
      }
    },
    onValidateBoxLadingBill(sku) {
      let is_sku_exist_in_lading_bill =
        this.invoice_selected.boxes_in_lading_bills.findIndex(
          (i) => i.id == sku
        ) !== -1;

      if (!is_sku_exist_in_lading_bill) {
        this.onEnterDataError(sku, "sku-not-exist-in-lading-bill");
        this.$store.commit("toasts.push", {
          title: this.$t("Invalid SKU") + ": " + sku,
          message: "SKU not exist in lading bill",
          type: "danger",
        });
      }

      return is_sku_exist_in_lading_bill;
    },
    onValidateQuantityOutContainer(sku, next_quantity) {
      let in_picker_quantity = this.invoice_selected.in_pickers
        .filter((i) => i.box_id === sku)
        .reduce((p, c) => p + c.quantity, 0);
      let out_picker_quantity = this.out_container_pickers
        .filter((i) => i.box_id === sku)
        .reduce((p, c) => p + c.quantity, 0);
      let api_processing_quantity = this.in_api_process_boxes
        .filter((i) => i.id === sku)
        .reduce((p, c) => p + c.quantity, 0);

      let is_valid_quantity_out_picker =
        next_quantity <=
        in_picker_quantity - out_picker_quantity - api_processing_quantity;

      if (!is_valid_quantity_out_picker) {
        this.onEnterDataError(sku, "over-quantity-in-container");
        this.$store.commit("toasts.push", {
          title: this.$t("Invalid SKU") + ": " + sku,
          message: "Over quantity in list in container",
          type: "danger",
        });
      }

      return is_valid_quantity_out_picker;
    },
    onEnterPicker(sku) {
      /**
       * 1. Nếu container chưa đóng hoặc chưa chọn container thì return, k làm gì cả
       * 2. Nếu SKU Empty, cũng không làm gì cả
       * 3. Validate xem sku có tồn tại trong lading-bill hay không
       * 4. Validate số lượng out-cont < số lượng in-cont. Không cần validate SL trong lading_bill vì số lượng in-cont luôn luôn đảm bảo
       */

      if (!this.invoice_selected.had_cut_off || !this.invoice_id_selected) {
        this.playFail();
        return;
      }

      if (this.lodash.isEmpty(sku)) return;

      if (!this.onValidateBoxLadingBill(sku)) return;

      const current_picking = this.picking_boxes.find((p) => p.box.id === sku);

      const quantity = (current_picking?.quantity || 0) + 1;

      if (!this.onValidateQuantityOutContainer(sku, quantity)) return;

      let box = this.invoice_selected.boxes_in_lading_bills.find(
        (b) => b.id === sku
      );

      PICK_OUT_CONT.set(
        {
          id: box.id,
          quantity,
          box,
          picking: true,
        },
        this.invoice_id_selected
      );
      this.fetchPickingBoxes();
      this.playSuccess();
    },
    triggerSearch(value) {
      this.$store.commit("warehouse.invoices_out.push-query", {
        "filter[to_area_id]": warehouseScope.get(),
      });
      if (value || value == "") {
        this.$store.dispatch("warehouse.invoices_out.apply-query", {
          "filter[name]": value,
        });
      } else this.$store.dispatch("warehouse.invoices_out.fetch.if-first-time");
    },
    updateQuantityPicker(picker, quantity) {
      if (picker.picking) {
        if (!this.onValidateQuantityOutContainer(picker.id, quantity)) return;

        PICK_OUT_CONT.set({ ...picker, quantity }, this.invoice_id_selected);
        this.fetchPickingBoxes();
        return;
      }
      this.$store
        .dispatch("warehouse.out_container_pickers.update", {
          id: picker.id,
          attributes: { quantity },
        })
        .then(() => {
          this.fetchInvoice();
        });
    },
    removePicker(picker) {
      if (picker.picking) {
        PICK_OUT_CONT.removeSku(this.invoice_id_selected, picker);
        this.fetchPickingBoxes();
        return;
      }

      this.$set(picker, "removing", true);
      this.$store
        .dispatch("warehouse.out_container_pickers.delete", picker.id)
        .finally(() => {
          this.$set(picker, "removing", false);
          this.fetchInvoice();
        });
    },
    getBoxCardPickingBackground(picker) {
      return picker.quantity +
        (picker?.box?.quantity_out_picker_in_cont || 0) ===
        (picker?.box?.total_quantity_in_lading_bills || 0)
        ? "#d9f7be"
        : "#fff";
    },
    getBoxCardBackground(box) {
      const total_quantity_in_lading_bills =
        box.total_quantity_in_lading_bills || 0;

      if (
        total_quantity_in_lading_bills == 0 ||
        box?.quantity_out_picker_in_cont > total_quantity_in_lading_bills
      )
        return "#ffa39e";

      if (box?.quantity_out_picker_in_cont === total_quantity_in_lading_bills)
        return "#d9f7be";

      if (
        box?.quantity_out_picker_in_cont &&
        box?.quantity_out_picker_in_cont ===
          this.getPickedInContainerQuantity(box.id)
      )
        return "#fffb8f";

      return "#fff";
    },
    getTotalQuantityInLadingBills(picker) {
      if (!this.invoice_selected.boxes_in_lading_bills) {
        return 0;
      }
      const box = this.invoice_selected?.boxes_in_lading_bills.find(
        (b) => b.id === picker.box.id
      );

      if (!box) {
        return 0;
      }
      return box.total_quantity_in_lading_bills;
    },
    submitPickers() {
      let data = {
        container_id: this.invoice_selected.id,
      };
      this.in_api_process_boxes = [
        ...this.in_api_process_boxes,
        ...this.picking_boxes,
      ];
      this.picking_boxes = [];
      this.in_api_process_boxes.forEach((picker) => {
        PICK_OUT_CONT.removeSku(this.invoice_id_selected, picker);
      });

      this.in_api_process_boxes.forEach((object, index) => {
        const item = {
          id: object.id,
          quantity: object.quantity,
          pallet_id: object.box.palletSelected,
        };
        const boxItem = this.lodash.mapKeys(item, (value, key) => {
          return `boxes[${index}][${key}]`;
        });
        data = { ...data, ...boxItem };
      });
      this.$store
        .dispatch(
          "warehouse.out_container_pickers.create",
          this.lodash.pickBy(data, this.lodash.identity)
        )
        .then(() => {
          // this.picking_boxes.forEach((picker) => {
          //   PICK_OUT_CONT.removeSku(this.invoice_id_selected, picker);
          // });
          // this.fetchPickingBoxes();
          this.fetchOutPickers().finally(
            () => (this.in_api_process_boxes = [])
          );
          this.fetchInvoice();
        })
        .catch(() => {
          this.in_api_process_boxes.forEach((picker) => {
            PICK_OUT_CONT.set(picker, this.invoice_id_selected);
          });
          this.fetchPickingBoxes();
          this.in_api_process_boxes = [];
        });
    },
    exportPicker() {
      this.$store
        .dispatch("helper.exports.export-container-picker", {
          container_id: this.invoice_id_selected,
        })
        .then(({ data, filename }) => {
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(data);
          link.download = filename;
          link.click();
        });
    },
    submitExcelSuccess() {
      this.fetchOutPickers();
      this.fetchInvoice();
    },
  },
};
</script>
