
export default {
  methods: {
    handleReadDWSSKU(e) {
      const data = JSON.parse(e.data);
      if (data.type == "DWS_SKU") {
        this.enterPicker(data.box_id);
      }
    },
  },
  mounted() {
    setTimeout(() => {
      if (this.$store.getters["printer.value"]?.readyState)
        this.$store.getters["printer.value"].addEventListener(
          "message",
          this.handleReadDWSSKU
        );
    }, 1000);
  },
  destroyed() {
    if (this.$store.getters["printer.value"]?.readyState)
      this.$store.getters["printer.value"].removeEventListener(
        "message",
        this.handleReadDWSSKU
      );
  },
}
