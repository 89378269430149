const STORE_KEY = "pick_out_cont";

export const get = (invoice_id) => {
  return JSON.parse(localStorage.getItem(`${STORE_KEY}-${invoice_id}`));
};

export const set = (picker, invoice_id) => {
  const old_pickers = get(invoice_id);
  if (!old_pickers) {
    return localStorage.setItem(`${STORE_KEY}-${invoice_id}`, JSON.stringify([picker]));
  }
  var pickers = old_pickers;
  const existed = old_pickers.find(p => p.id === picker.id);
  if (existed) {
    pickers = old_pickers.map(p => {
      if (p.id === picker.id) {
        return picker;
      }
      return p;
    })
  } else {
    pickers = [picker, ...pickers]
  }
  return localStorage.setItem(`${STORE_KEY}-${invoice_id}`, JSON.stringify(pickers));
};

export const remove = (invoice_id) => {
  localStorage.removeItem(`${STORE_KEY}-${invoice_id}`);
}

export const removeSku = (invoice_id, picker) => {
  var pickers = get(invoice_id);
  const pAt = pickers.findIndex(p => p.id === picker.id)
  pickers.splice(pAt, 1);
  return localStorage.setItem(`${STORE_KEY}-${invoice_id}`, JSON.stringify(pickers));
}

export default { get, set, remove, removeSku }
